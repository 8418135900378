<template>
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path
      d="M7.488 24h-.001c-.266 0-.487-.216-.487-.487v-.513c-1.104 0-2-.896-2-2v-13c0-1.104.896-2 2-2h2v-5c0-.552.448-1 1-1h4c.553 0 1 .449 1 1v5h2c1.105 0 2 .896 2 2v13c0 1.104-.895 2-2 2v.488c0 .283-.228.512-.512.512h-.001c-.265 0-.487-.214-.487-.487v-.513h-8v.488c0 .283-.229.512-.512.512m-.488-3h10v-13h-10v13zm2.008-10h-.508c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h7c.277 0 .5.224.5.5s-.223.5-.5.5h-4.532c.262.304.463.665.578 1.003l1.289.003 3.528 3.542-2.833 2.823-3.529-3.543.003-1.476c-.607-.415-1.068-1.314-.996-2.352m3.642 3.271l1.821 1.821-.354.353-1.82-1.82.353-.354zm.684 1.745l-.354.353-1.214-1.214.354-.353 1.214 1.214zm-.154-2.275l1.821 1.821-.353.353-1.821-1.82.353-.354zm-1.572-.726c-.092.274-.333.462-.606.539.042.417.551.6.85.303.281-.278.139-.755-.244-.842m-2.097-2.015c-.053.687.156 1.287.494 1.682l.002-.682 1.003.002c-.189-.434-.466-.774-.764-1.002h-.735zm4.489-5v-3.5c0-.276-.223-.5-.5-.5h-3c-.276 0-.5.224-.5.5v3.5h4z"
    />
  </svg>
</template>

<script>
export default { name: "Packages" };
</script>

<style></style>
