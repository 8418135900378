<template>
  <header class="navbar-expand-sm bg-dark navbar-dark fixed-top header_height">
    <div class="container-fluid" style="padding-inline: 50px 15px">
      <div class="row align-items-center">
        <div class="col-md-9">
          <div class="row">
            <!--  <div class="col-md-2 pr-0">
              <img src="/images/travlover.png" alt="Travlover" title="Travlover" />
            </div> -->
            <div class="col-md-10 pl-2">
              <span class="suppliername">{{ user.suppliername }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 profile">
          <div
            v-click-outside="handleDropDown"
            class="profile_margin float-right"
          >
            <label
              data-toggle="dropdown"
              for="profile"
              class="profile_icon"
              href="javascript:void(0)"
            >
              <span class="profile_img_parent">
                <img
                  class="pro_img profile_img_dimention"
                  src="/images/user.png"
                />
                <a style="color: rgba(255, 255, 255, 0.5)">{{ user.name }}</a>
              </span>
            </label>
            <div>
              <input
                type="checkbox"
                class="dashboard-chk d-none"
                id="profile"
              />
              <div class="profile_list">
                <ul class="menu_list m-0">
                  <li class="list border-btm">
                    <span class="list_label licolor">Your Actions</span>
                  </li>
                  <li
                    @click="handleListRoute('/companydetails')"
                    class="list navSubmenu"
                  >
                    <a class="link">
                      <span>Company Details</span>
                    </a>
                  </li>
                  <li
                    @click="handleListRoute('/viewprofile')"
                    class="list navSubmenu"
                  >
                    <a class="link">
                      <span>View Profile</span>
                    </a>
                  </li>
                  <li
                    @click="handleListRoute('/change-password')"
                    class="list navSubmenu"
                  >
                    <a class="link">
                      <span>Change Password</span>
                    </a>
                  </li>
                  <li @click="handleSignoutUser" class="list navSubmenu">
                    <a class="link">
                      <span>Log out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  methods: {
    handleSignoutUser() {
      this.$store.dispatch("signoutUser");
    },
    handleListRoute(route) {
      document.querySelector("#profile").checked = false;
      this.$router.push(route);
    },
    handleDropDown() {
      document.querySelector("#profile").checked = false;
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.user;
    }
  }
};
</script>

<style scoped>
.header_height {
  height: 40px;
}

.navbar {
  padding: 0;
}
.border-btm {
  border-bottom: 1px solid #e9ebee;
}

.profile_margin {
  float: none;
  margin-top: 7px;
  position: relative;
}

.profile_icon {
  background-color: transparent;
  color: inherit;
  display: inline-block;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  height: 27px;
  line-height: 28px;
  padding: 0 10px 1px;
  position: relative;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  white-space: nowrap;
}

.profile .profile_icon:after {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  box-sizing: border-box;
  content: "";
  height: 24px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 24px;
}

.profile_icon:hover {
  background: rgba(0, 0, 0, 0.1);
  color: inherit;
}

.profile_icon span {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.profile_margin .profile_icon {
  position: relative;
}

.profile_icon span .pro_img {
  display: inline;
  overflow: hidden;
  margin: 2px 6px 2px -8px;
  vertical-align: inherit;
  border: 0;
}

.profile_icon span .pro-dashboard {
  display: inline;
  overflow: hidden;
  margin: -1px 6px 2px -8px;
  vertical-align: inherit;
  border: 0;
}

.profile_img_parent .profile_img_dimention {
  height: 24px;
  width: 24px;
}

.profile_list {
  position: absolute;
  background-color: #fff;
  color: #000;
  width: 135px;
  border-radius: 0 0 2px 2px;
  background-clip: padding-box;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  top: 36px;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.profile .dashboard-chk:checked ~ .profile_list {
  max-height: 300px;
  transition: max-height 0.5s ease-in-out;
}

ul.menu_list {
  padding: 5px 0;
}

li.list {
  overflow: hidden;
  white-space: nowrap;
}

.navSubmenu {
  display: block;
  max-width: 200px;
  padding: 0 8px;
  text-decoration: none;
  cursor: pointer;
}

.navSubmenu:hover {
  /* background-color: #80deea; */
  background-color: rgba(0, 0, 0, 0.1);
}

.navSubmenu .setting-theme {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navSubmenu a.link,
span.link {
  color: #1d2129;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  line-height: 26px;
  padding: 0 5px;
}

.list > .list_label {
  color: #1d2129;
  font-size: 11px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  line-height: 22px;
  padding: 0 6px;
}

.suppliername {
  display: block;
  color: #9da0a2;
  position: relative;
  top: 5px;
  font-size: 20px;
}
</style>
