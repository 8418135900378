<template>
  <component :is="tag">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "listitem",
  props: {
    tag: {
      type: String,
      default: "li"
    }
  }
};
</script>

<style></style>
