import { defaultClient as apolloClient } from "../../../main";
import {
  FETCH_PACKAGE_DATES_PRICING,
  UPDATE_PACKAGE_DATE_PRICING
} from "../../../queries/packages.queries";

const state = {
  isPkgDatesLoading: false,
  pkgDatesDetails: null,
  datesPricing: {
    adult: {
      single: "",
      singleallowed: true,
      twin: "",
      twinallowed: true,
      extrabed: "",
      extrabedallowed: true,
      surcharge: ""
    },
    child: {
      cwb: "",
      cwballowed: true,
      cnb: "",
      cnballowed: true,
      cwbsurcharge: "",
      cnbsurcharge: ""
    },
    "surcharge-description": "",
    infant: "",
    infantallowed: true
  }
};

const mutations = {
  setPackageDateLoading(state, value) {
    state.isPkgDatesLoading = value;
  },
  setPackageDateDetails(state, value) {
    state.pkgDatesDetails = value;
  }
};

const actions = {
  fetchPackageDatesPricing: ({ commit }, code) => {
    commit("setPackageDateLoading", true);
    commit("setPackageDateDetails", null);
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: FETCH_PACKAGE_DATES_PRICING,
          variables: { code }
        })
        .then(({ data }) => {
          resolve(data.fetchAvailableDates);
          commit("setPackageDateLoading", false);
          commit("setPackageDateDetails", data.fetchAvailableDates);
        })
        .catch(err => {
          commit("setPackageDateLoading", false);
          reject(err);
        });
    });
  },
  updatePackageDatesPricing: (_, payload) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: UPDATE_PACKAGE_DATE_PRICING,
          variables: { payload }
        })
        .then(({ data }) => {
          resolve(data.insertAndUpdateDates);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  isPkgDatesLoading: state => state.isPkgDatesLoading,
  pkgDatesDetails: state => state.pkgDatesDetails,
  datesPricing: state => state.datesPricing
};

export default {
  state,
  mutations,
  actions,
  getters
};
