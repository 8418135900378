export default {
  camel: function (str) {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  },
  textTruncate: function (value, length) {
    if (value.length > length) {
      return value.substring(0, length) + "...";
    }
    return value;
  },
  rupeeFormat: function (text, options) {
    if (isNaN(text)) {
      return text;
    }
    let is_negative = false;
    if (+text < 0) {
      is_negative = true;
      text = -text;
    }
    let value = text ? text.toString().replace(/,/g, "") : "";
    let val = Math.round(value).toString();

    let lastThree = val.substring(val.length - 3);
    let otherNumbers = val.substring(0, val.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    let formatText = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    if (options?.symbol) {
      return (options?.symbol || "") + formatText;
    }
    if (is_negative) {
      formatText = "-" + formatText;
    }
    return formatText;
  }
};
