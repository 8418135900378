import { gql } from "apollo-boost";
export const GET_DEST_INFO = gql`
  query {
    getDestInfo {
      dest_id
      destName
      visa_info
      type
      documents
      terms_conditions
    }
  }
`;
export const CREATE_AND_UPDATE_INFO = gql`
  mutation($data: NewSupplierDestination!) {
    createDestnRequirements(data: $data) {
      dest_id
      type
      destName
    }
  }
`;
export const GET_DEST_INFO_BY_ID = gql`
  query($input: String!) {
    getDestInfoById(input: $input) {
      dest_id
      destName
      visa_info
      documents
      terms_conditions
    }
  }
`;
