import { gql } from "apollo-boost";
export const GET_PKG_DISCOUNT_BY_CODE = gql`
  query($code: String) {
    getPkgByCode(code: $code) {
      name
      code
      options
    }
  }
`;

export const UPDATE_DISCOUNTS = gql`
  mutation($input: UpdateDiscount!, $type: String) {
    updateAndDeleteDiscount(input: $input, type: $type) {
      value
      discount {
        adult
        cwb
        cnb
        infant
      }
      option
      package
      type
    }
  }
`;

export const GET_ALL_DISCOUNTS = gql`
  query($input: UpdateDiscount!) {
    getDiscountsList(input: $input) {
      value
      discount {
        adult
        cwb
        cnb
        infant
      }
      option
      package
      type
    }
  }
`;
