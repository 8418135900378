import { defaultClient as apolloClient } from "../../main";
import { SIGNIN_USER, GET_CURRENT_USER } from "../../queries/auth.queries";
import router from "../../router";
const state = {
  user: null,
  error: null,
  authError: null,
  loading: false
};
const mutations = {
  clearUser: state => (state.user = null),
  setLoading: (state, payload) => (state.loading = payload),
  setUser: (state, payload) => (state.user = payload),
  clearError: state => (state.error = null),
  setError: (state, payload) => {
    const errMsg = payload.message.replace("GraphQL error:", "Error: ");
    payload.message = errMsg;
    return (state.error = payload);
  },
  setAuthError: (state, payload) => (state.authError = payload)
};
const actions = {
  getCurrentUser: ({ commit }) => {
    commit("setLoading", true);
    apolloClient
      .query({
        query: GET_CURRENT_USER
      })
      .then(({ data }) => {
        commit("setLoading", false);
        //add user data to state.
        commit("setUser", data.getCurrentAffiliateUser);
      })
      .catch(err => {
        commit("setLoading", false);
        commit("setError", err);
      });
  },
  signinUser: ({ commit }, payload) => {
    commit("setLoading", true);
    commit("clearError");
    apolloClient
      .mutate({
        mutation: SIGNIN_USER,
        variables: payload
      })
      .then(({ data }) => {
        commit("setLoading", false);
        localStorage.setItem("token", data.signinAffiliateUser.token);
        window.location.replace("/");
        //to make sure created method is run in main.js (we run getCurrentUser), reload the page.

        // router.go();
      })
      .catch(err => {
        commit("setLoading", false);
        commit("setError", err);
      });
  },
  signoutUser: async ({ commit }) => {
    //clear user in state
    commit("clearUser");
    //remove token in localStorage
    localStorage.setItem("token", "");

    //end session
    await apolloClient.resetStore();

    // redirect to login
    router.push("/signin");
  }
};
const getters = {
  user: state => state.user,
  signinError: state => state.error,
  authError: state => state.authError,
  loading: state => state.loading
};

export default {
  state,
  mutations,
  actions,
  getters
};
