import Vue from "vue";
const state = {
  selectedOption: "",
  options: {
    isSingleHotel: false,
    items: []
  },
  flights: {
    onward: [],
    interconnect: [],
    return: []
  },
  newSelectedDates: [],
  sightseeing: [],
  transfers: [],
  bus: [],
  hotels: [],
  meals: [],
  trains: [],
  visa: "",
  taxes: "",
  cruise: [],
  prices: {
    adult: {
      single: "",
      singleallowed: true,
      twin: "",
      twinallowed: true,
      extrabed: "",
      extrabedallowed: true,
      surcharge: ""
    },
    child: {
      cwb: "",
      cwballowed: true,
      cnb: "",
      cnballowed: true,
      cwbsurcharge: "",
      cnbsurcharge: ""
    },
    "surcharge-description": "",
    infant: "",
    infantallowed: true
  }
};

const mutations = {
  /* Manage Package Type of Optiosn Or Room Categories */
  managePackageType({ options }, type) {
    options.isSingleHotel = type;
  },
  /* Manage Option Types For Packages */
  changeOptionType(state, option) {
    state.selectedOption = option;
  },
  /* Add New Package Options As Per Selected Type of Package*/
  addNewOptions({ options, newSelectedDates, meals, hotels }, { name, is_honeymoon, selectIndex }) {
    /* const optIndex = options.items.findIndex(data => data.type == name); */
    const newDateIndex = newSelectedDates.findIndex((data) => data.type == name);
    const mealsIndex = meals.findIndex((data) => data.type == name);
    const hotelsIndex = hotels.findIndex((data) => data.type == name);
    /* optIndex == -1 && */
    if (selectIndex == null && newDateIndex == -1 && mealsIndex == -1 && hotelsIndex == -1) {
      const newObj = {
        type: name,
        is_honeymoon,
        isNew: true,
        meals: Vue.observable({ breakfast: false, breakfast_amount: "", lunch: false, lunch_amount: "", dinner: false, dinner_amount: "" }),
        dates: Vue.observable({}),
        active: true,
        hotels: []
      };
      options.items.push(newObj);
      newSelectedDates.push(newObj);
      meals.push(newObj);
      hotels.push(newObj);
    } else if (selectIndex != null) {
      options.items.splice(selectIndex, 1, {
        ...options.items[selectIndex],
        type: name,
        is_honeymoon
      });
      newSelectedDates.splice(selectIndex, 1, {
        ...newSelectedDates[selectIndex],
        type: options.items[selectIndex].type,
        is_honeymoon: options.items[selectIndex].is_honeymoon
      });
      meals.splice(selectIndex, 1, {
        ...meals[selectIndex],
        type: options.items[selectIndex].type,
        is_honeymoon: options.items[selectIndex].is_honeymoon
      });
      hotels.splice(selectIndex, 1, {
        ...hotels[selectIndex],
        type: options.items[selectIndex].type,
        is_honeymoon: options.items[selectIndex].is_honeymoon
      });
    } else {
      Vue.toasted.info("item is exists", {
        position: "bottom-center",
        duration: 2000
      });
    }
  },
  /**
   * Make Option Is Old OR Updated
   *
   * @date 2020-03-03
   * @param {*} { options, newSelectedDates, meals }
   */
  makeOptionsOld({ options, newSelectedDates, meals, hotels }) {
    options.items = options.items.map((data) => {
      if (data.isNew) {
        data.isNew = false;
      }
      return data;
    });
    newSelectedDates = newSelectedDates.map((data) => {
      if (data.isNew) {
        data.isNew = false;
      }
      return data;
    });
    meals = meals.map((data) => {
      if (data.isNew) {
        data.isNew = false;
      }
      return data;
    });
    hotels = hotels.map((data) => {
      if (data.isNew) {
        data.isNew = false;
      }
      return data;
    });
  },

  /* Remove Package Options */
  removeOptionsType({ options, newSelectedDates, meals, hotels }, index) {
    options.items.splice(index, 1);
    newSelectedDates.splice(index, 1);
    meals.splice(index, 1);
    hotels.splice(index, 1);
  },
  /* Add or Remove Dates When Select Dates Option And Categories wise */
  manageSelectionDates({ newSelectedDates, selectedOption }, { date, update = false, active = true }) {
    const OptIndex = newSelectedDates.findIndex((value) => value.type == selectedOption);
    if (OptIndex != -1) {
      let updateData = Vue.observable({
        value: date.format("DD"),
        active,
        prices: Vue.observable({})
      });
      if (update) {
        updateData = Vue.observable({ ...updateData, update });
      }
      if (
        newSelectedDates[OptIndex].dates.hasOwnProperty([date.format("MM-YYYY")]) &&
        Array.isArray(newSelectedDates[OptIndex].dates[date.format("MM-YYYY")]) &&
        newSelectedDates[OptIndex].dates[date.format("MM-YYYY")].findIndex((data) => {
          const matchDate = Vue.moment(`${date.format("YYYYMM")}${data.value}`);
          return matchDate.isSame(date);
        }) == -1
      ) {
        newSelectedDates[OptIndex].dates[date.format("MM-YYYY")].push(updateData);
      } else if (!Array.isArray(newSelectedDates[OptIndex].dates[date.format("MM-YYYY")])) {
        newSelectedDates[OptIndex].dates[date.format("MM-YYYY")] = [];
        newSelectedDates[OptIndex].dates[date.format("MM-YYYY")].push(updateData);
      } else {
        const findIndex = newSelectedDates[OptIndex].dates[date.format("MM-YYYY")].findIndex((data) => {
          const matchDate = Vue.moment(`${date.format("YYYYMM")}${data.value}`);
          return matchDate.isSame(date);
        });
        newSelectedDates[OptIndex].dates[date.format("MM-YYYY")].splice(findIndex, 1);
        if (newSelectedDates[OptIndex].dates[date.format("MM-YYYY")].length == 0) {
          delete newSelectedDates[OptIndex].dates[date.format("MM-YYYY")];
        }
      }
    }
  },
  /* Make A date active or in active */
  handleUpdateAndActiveDates({ options, selectedOption }, { date, type }) {
    const OptIndex = options.items.findIndex((value) => value.type == selectedOption);
    if (OptIndex != -1) {
      const findIndex = options.items[OptIndex].dates[date.format("MM-YYYY")].findIndex((data) => {
        const matchDate = Vue.moment(`${date.format("YYYYMM")}${data.value}`);
        return matchDate.isSame(date);
      });
      if (findIndex != -1) {
        if (type == "disabled") {
          const dataValue = options.items[OptIndex].dates[date.format("MM-YYYY")][findIndex];
          this.commit("manageSelectionDates", {
            date,
            active: !dataValue.active
          });
        } else if (type == "updateall") {
          this.commit("manageSelectionDates", {
            date,
            update: true
          });
        }
      }
    }
  },
  /* Manage Hotels List Of Packages */
  addAndRemoveHotels({ hotels, options }, { value, type, updateIndex, opt }) {
    const optIndex = hotels.findIndex((data) => data.type == opt);
    if (type == "add" && !options.isSingleHotel) {
      hotels[optIndex].hotels.push(value);
    } else if (type == "remove" && !options.isSingleHotel) {
      hotels[value.parentIndex].hotels.splice(value.childIndex, 1);
    } else if (type == "add" && options.isSingleHotel) {
      hotels.filter((data) => data.hotels.push(value));
    } else if (type == "remove" && options.isSingleHotel) {
      hotels.filter((data) => data.hotels.splice(value.childIndex, 1));
    } else if (type == "update") {
      hotels.filter((data) => {
        if (data.type == opt) {
          data.hotels[updateIndex] = value;
          return data;
        } else {
          return data;
        }
      });
      //newSelectedDates[optIndex].hotels[updateIndex] = value;
    }
  },
  /* Manage Update Mode And Set Exists Data */
  isFieldsDataExists(state, { value, type }) {
    state[type] = value;
  },
  /* Manage Package Services Details */
  managePackageServices(state, { type, index, existsData, service, value }) {
    if (existsData || typeof existsData == "string") {
      state[service] = existsData;
    } else if (type == "add") {
      state[service].push(value);
    } else {
      state[service].splice(index, 1);
    }
  },
  managePackageSightseeing(state, { data, index }) {
    if (index == "list_update") {
      state.sightseeing = data;
    } else if (index != null) {
      state.sightseeing[index] = data;
    } else {
      state.sightseeing.push(data);
    }
  },
  managePackageTransfers(state, { data, index }) {
    if (index == "list_update") {
      state.transfers = data;
    } else if (index != null) {
      state.transfers[index] = data;
    } else {
      state.transfers.push(data);
    }
  },
  managePackageFlights(state, { type, route, index, value }) {
    if (type == "add") {
      state.flights[route].push(value);
    } else {
      state.flights[route].splice(index, 1);
    }
  },
  resetPricesOnChange(state) {
    state.prices = {
      adult: {
        single: "",
        singleallowed: true,
        twin: "",
        twinallowed: true,
        extrabed: "",
        extrabedallowed: true,
        surcharge: ""
      },
      child: {
        cwb: "",
        cwballowed: true,
        cnb: "",
        cnballowed: true,
        cwbsurcharge: "",
        cnbsurcharge: ""
      },
      "surcharge-description": "",
      infant: "",
      infantallowed: true
    };
  },
  setInputTextData(state, { value, type }) {
    state[type] = value;
  },
  setNewSelectionDate(state) {
    state.newSelectedDates = state.newSelectedDates.map((data) => ({
      ...data,
      dates: Vue.observable({})
    }));
  },
  updateAvailableHotels(state, optionData) {
    const _htIndex = state.hotels.findIndex((data) => data?.type?.toLowerCase() == optionData?.type?.toLowerCase());
    const optIndex = state?.options?.items.findIndex((data) => data?.type?.toLowerCase() == optionData?.type?.toLowerCase());
    if (_htIndex != -1) {
      state.hotels.splice(_htIndex, 1, optionData);
    }
    if (optIndex != -1) {
      state.options.items.splice(optIndex, 1, {
        ...state.options.items[optIndex],
        hotels: optionData.hotels
      });
    }
  }
};

const actions = {};

const getters = {
  pkgOptions: (state) => state.options,
  selectedOptionType: (state) => state.selectedOption,
  packageCruise: (state) => state.cruise,
  packageBus: (state) => state.bus,
  packageTransfers: (state) => state.transfers,
  packageSightseeing: (state) => state.sightseeing,
  packageFlights: (state) => state.flights,
  datesPrices: (state) => state.prices,
  newSelectedDates: (state) => state.newSelectedDates,
  availableHotels: (state) => state.hotels,
  packageMeals: (state) => state.meals,
  packageVisa: (state) => state.visa,
  packageTaxes: (state) => state.taxes,
  packageTrains: (state) => state.trains
};

export default {
  state,
  mutations,
  actions,
  getters
};
