import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home.vue";
import NotFound from "./components/not-found.vue";
import AuthGuard from "./AuthGuard";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      beforeEnter: AuthGuard
    },
    {
      path: "/signin",
      name: "signin",
      component: () =>
        import(/* webpackChunkName: "signin" */ "./components/Auth/SignIn.vue")
    },
    {
      path: "/viewprofile",
      name: "viewprofile",
      component: () =>
        import(
          /* webpackChunkName: "signin" */ "./components/Profile/Supplier/UserProfile.vue"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/companydetails",
      name: "company-details",
      component: () =>
        import(
          /* webpackChunkName: "signin" */ "./components/Profile/Supplier/SupplierDetails.vue"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/change-password",
      name: "change-password",
      component: () =>
        import(
          /* webpackChunkName: "signin" */ "./components/Profile/changePassword.vue"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/users/listing",
      name: "manageuser",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/User/Users.vue"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/users/create",
      name: "createuser",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/User/CreateUser.vue"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/users/menu",
      name: "managemenus",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Menus/manage.vue"
        ),
      beforeEnter: AuthGuard
    },

    /* Package Menu Sections */
    {
      path: "/package/create",
      name: "createpackage",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/create"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/packages/available-dates",
      name: "AvailableDates",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/available-dates"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/package/listing",
      name: "packagelisting",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/listing"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/package/extra-details",
      name: "package-details",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/extraDetails"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/package/discount",
      name: "packagediscount",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/discount"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/package/hotels",
      name: "packagehotels",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/hotels"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/package/sightseeing",
      name: "packagesightseeing",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/sightseeing"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/package/seo",
      name: "package-seo",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Packages/Seo"
        ),
      beforeEnter: AuthGuard
    },
    // {
    //   path: "/masters/media/listing",
    //   name: "managemedia",
    //   component: () =>
    //     import(/* webpackChunkName: "createdestination" */ "./components/Masters/managemedia.vue"),
    //   beforeEnter: AuthGuard
    // },
    // {
    //   path: "/masters/media/create",
    //   name: "createmedia",
    //   component: () =>
    //     import(/* webpackChunkName: "createdestination" */ "./components/Masters/createmedia.vue"),
    //   beforeEnter: AuthGuard
    // },
    {
      path: "/masters/info/create",
      name: "createinfo",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Masters/createinformation.vue"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "/masters/info/listing",
      name: "viewinfo",
      component: () =>
        import(
          /* webpackChunkName: "createdestination" */ "./components/Masters/information.vue"
        ),
      beforeEnter: AuthGuard
    },
    {
      path: "**",
      name: "notfound",
      component: NotFound
    }
  ]
});
