import Vue from "vue";
import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
import Vuelidate from "vuelidate";
import VueAutosuggest from "vue-autosuggest";
import DatePicker from "vuejs-datepicker";
import Toasted from "vue-toasted";
import VueLodash from "vue-lodash";
import VueMoment from "vue-moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Spinner from "./components/UI/Spinner.vue";
import PopupModel from "./components/UI/Popup.vue";
import loader from "./components/UI/loader.vue";
import MenuItem from "./components/utils/menuitems.vue";
import VListitem from "./components/utils/list-item.vue";
import VArrowbtn from "./components/utils/arrowbtn.vue";
import Viewer from "v-viewer";
import VArrowicon from "./components/utils/arrow-icon.vue";
import Container from "./components/utils/container.vue";
import VCalender from "./components/utils/calender";
import { directives, filters } from "./utils";
import { VueEditor } from "vue2-editor";
import "viewerjs/dist/viewer.css";
import Moment from "./utils/moment";
import lodash from "lodash";
Vue.use(VueLodash, { lodash: lodash });
Vue.component("Spinner", Spinner);
Vue.component("DatePicker", DatePicker);
Vue.component("PopupModel", PopupModel);
Vue.component("Loader", loader);
Vue.component("VueEditor", VueEditor);
Vue.component("MenuItem", MenuItem);
Vue.component("Container", Container);
Vue.component("VListitem", VListitem);
Vue.component("VArrowbtn", VArrowbtn);
Vue.component("VCalender", VCalender);
Vue.component("VArrowicon", VArrowicon);
Vue.use(Toasted);
Vue.use(VueApollo);
Vue.use(Viewer);
Vue.use(VueMoment);
Vue.use(Moment);
Vue.use(Vuelidate);
Vue.use(VueAutosuggest);
//setup apollo client

/* Bind All Directives */
for (let dir in directives) {
  Vue.directive(dir, directives[dir]);
}

/* Bind All Filters */
for (let dir in filters) {
  Vue.filter(dir, filters[dir]);
}

export const defaultClient = new ApolloClient({
  uri: process.env.VUE_APP_GRAPHQL_URI,
  // include auth token with request made to backend
  fetchOptions: {
    credentials: "include"
  },
  request: (operation) => {
    // if there is no token with the key 'token' in local storate , then add it.
    if (!localStorage.token) {
      localStorage.setItem("token", "");
    }
    // operation is a function which adds the token to an authorization header, which is sent to backend.
    operation.setContext({
      headers: {
        Authorization: localStorage.getItem("token")
      }
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (networkError) {
      //console.log("[networkError]: ", networkError);
    }
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.name === "AuthenticationError") {
          //set auth error in state
          store.commit("setAuthError", err);
          //signout user (to clear token)
          store.dispatch("signoutUser");
        }
      }
    }
  }
});

const inputTypes = {
  number: (e) => {
    const code = e.keyCode || e.charCode || e.which;
    if (code < 48 || code > 57) {
      e.preventDefault();
      return false;
    }
  },
  number_negative: (e) => {
    const code = e.keyCode || e.charCode || e.which;
    if ((code < 48 || code > 57) && code !== 45) {
      e.preventDefault();
      return false;
    }
  },
  alphabets: (e) => {
    const code = e.keyCode || e.charCode || e.which;
    let notValid = false;
    if ((code >= 97 && code <= 122) || (code >= 65 && code <= 90) || code == 32) {
      notValid = true;
    }
    if (!notValid) {
      e.preventDefault();
      return notValid;
    }
  },
  alphanumeric: (e) => {
    const code = e.keyCode || e.charCode || e.which;
    let notValid = false;
    if ((code >= 97 && code <= 122) || (code >= 65 && code <= 90) || (code >= 48 && code <= 57) || code == 32) {
      notValid = true;
    }
    if (!notValid) {
      e.preventDefault();
      return notValid;
    }
  }
};

Vue.directive("inputType", {
  bind: function (el, bind, vueNode) {
    const handler = inputTypes[bind.value];
    el.addEventListener("keypress", handler);
  },

  unbind: function (el) {
    el.removeEventListener("keypress", el.__vueInputType__);
  }
});

defaultClient.defaultOptions = {
  query: {
    fetchPolicy: "no-cache"
  }
};

const apolloProvider = new VueApollo({ defaultClient });

new Vue({
  apolloProvider,
  router,
  store,
  render: (h) => h(App),
  created() {
    // execute getCurrentUser query so that the info is available on every page.
    this.$store.dispatch("getCurrentUser");
  }
}).$mount("#app");
