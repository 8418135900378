<template>
  <span @click="$emit('onclick')" :class="`li-move-${type}`">
    <svg
      version="1.1"
      id="Svg40"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="10px"
      height="15px"
      viewBox="0 0 8 12"
      xml:space="preserve"
    >
      <path
        d="M5.146,0c0.274,0.125,0.359,0.339,0.357,0.624
	                                                    C5.499,2.691,5.502,4.759,5.502,6.827c0,0.051,0,0.102,0,0.172c0.06,0,0.108,0,0.159,0c0.601,0,1.2-0.001,1.8,0.002
	                                                    c0.284,0.001,0.494,0.171,0.533,0.433c0.028,0.173-0.047,0.312-0.156,0.437c-0.701,0.801-1.401,1.604-2.103,2.401
	                                                    c-0.439,0.504-0.881,1.008-1.322,1.511c-0.253,0.288-0.571,0.291-0.826,0C2.446,10.48,1.306,9.177,0.166,7.874
	                                                    c-0.147-0.17-0.22-0.356-0.118-0.573C0.149,7.083,0.33,6.999,0.564,7c0.586,0.003,1.173,0,1.76,0c0.051,0,0.103,0,0.177,0
	                                                    c0-0.063,0-0.118,0-0.171c0-2.077,0.001-4.153-0.003-6.229c0-0.278,0.096-0.477,0.358-0.599C5.146,0,2.856,0,5.146,0z"
        style="fill-rule: evenodd; clip-rule: evenodd; fill: rgb(23, 162, 184);"
      ></path>
    </svg>
  </span>
</template>

<script>
export default {
  name: "Arrow",
  props: {
    type: {
      type: String,
      default: "up"
    }
  }
};
</script>

<style></style>
