import { defaultClient as apolloClient } from "../../main";
import {
  CHANGE_PASSWORD,
  GET_USER_BY_ID,
  UPDATE_USER_PROFILE,
  CREATE_AFFILIATE_USER,
  UPDATE_AFFILIATE_USER,
  GET_AFFILIATE_USERS,
  GET_AFFILIATE_USER_BY_ID
} from "../../queries/user.queries";
import router from "../../router";

const state = {
  users: [],
  userEdit: {},
  userExists: false,
  error: null
};
const mutations = {
  clearError: () => (state.error = null),
  setError: (state, payload) => {
    state.error = payload;
  },
  setUsers: (state, payload) => {
    state.users = payload;
  },
  setExistingUser: (state, payload) => {
    state.userExists = payload;
  },
  setUserEdit: (state, payload) => {
    state.userEdit = payload;
  }
};
const actions = {
  createSupplierUser: ({ commit }, payload) => {
    commit("clearError");

    apolloClient
      .mutate({
        mutation: CREATE_AFFILIATE_USER,
        variables: { input: payload }
      })
      .then(() => {
        router.push("/users/listing");
      })
      .catch((err) => {
        commit("setError", err);
      });
  },
  updateSupplierUser: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .query({
          query: UPDATE_AFFILIATE_USER,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.userEdit);
          commit("setUserEdit", data.userEdit);

          router.push("/users/listing");
        })
        .catch((err) => {
          reject(err);
          commit("setError", err);
        });
    });
  },
  changePassword: ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .mutate({
          mutation: CHANGE_PASSWORD,
          variables: { input: payload }
        })
        .then((data) => {
          dispatch("signoutUser");
          resolve(data.changeSupplierPassword);
        })
        .catch((err) => {
          reject(err);
          commit("setError", err);
        });
    });
  },
  getUserProfile: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .query({
          query: GET_USER_BY_ID,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.affiliateExists);
          //commit("setAffiliatesEdit", data.affiliateExists);
          commit("setLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("setError", err);
        });
    });
  },
  updateUserProfile: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .mutate({
          mutation: UPDATE_USER_PROFILE,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.updateUserProfile);
          commit("setLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("setError", err);
        });
    });
  },
  getSupplierUsers: ({ commit }) => {
    //use apoloClient to fire getUsers query
    apolloClient
      .query({
        query: GET_AFFILIATE_USERS
      })
      .then(({ data }) => {
        // get data from action to state via mutation.
        // commit allows us to pass data from actions to state through the use of mutations.
        commit("setUsers", data.getAffiliateUsers);
      })
      .catch((err) => {
        return err;
      });
  },
  getSupplierUserById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .query({
          query: GET_AFFILIATE_USER_BY_ID,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.getAffiliateUserById);
        })
        .catch((err) => {
          reject(err);
          commit("setError", err);
        });
    });
  },
  updateExtraDetails: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .mutate({
          mutation: UPDATE_USER_PROFILE,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.supplierExists);
          commit("setLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("setError", err);
        });
    });
  },
  existingUser: async ({ commit }, payload) => {
    commit("clearError");

    await apolloClient
      .query({
        query: GET_USER_BY_ID,
        variables: { input: payload }
      })
      .then(({ data }) => {
        if (data.affiliateExists) {
          commit("setExistingUser", true);
        } else {
          commit("setExistingUser", false);
        }
      })
      .catch((err) => {
        commit("setError", err);
      });
  }
};

const getters = {
  users: (state) => state.users,
  error: (state) => state.error,
  userExists: (state) => state.userExists,
  userEdit: (state) => state.userEdit
};
export default {
  actions,
  state,
  getters,
  mutations
};
