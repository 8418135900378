<template>
  <div v-if="menumatch">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "menu-item",
  props: {
    pageUrl: {
      type: String
    }
  },
  computed: {
    ...mapGetters(["user"]),
    menumatch() {
      if (this.user) {
        for (const menu of this.user.menuitems) {
          for (const item of menu.child) {
            if (item.pageUrl.toLowerCase() === this.pageUrl.toLowerCase()) {
              return true;
            }
          }
        }
        return false;
      }
      return false;
    }
  }
};
</script>

<style></style>
