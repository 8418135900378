import { gql } from "apollo-boost";

export const CREATE_NEW_PACKAGE = gql`
  mutation ($data: NewPackageBasic!) {
    createPackage(data: $data) {
      _id
      name
      code
    }
  }
`;

export const GET_PACKAGE_LISTING = gql`
  query ($data: JSON) {
    getAllPackages(data: $data) {
      _id
      name
      code
      total_nights
      booking_amount {
        adult
        cwb
        cnb
        infant
      }
      ageBands {
        type
        ageFrom
        ageTo
      }
      destinations_covered
      overview_long
      active
      ims_pricing
      ims_airline
      ims_markup_discount
      pkg_type
      slug
      isPublishRequest
      isPublished
      isRejected
      is_feature
      is_b2c
      is_b2b
      is_wl
      rejectedMessage
      updateUser {
        _id
        name
      }
      createUser {
        _id
        name
      }
    }
  }
`;
export const GET_PACKAGES_BY_COUNTRY = gql`
  query ($input: Object!) {
    getPackagesByCountry(input: $input) {
      _id
      name
      code
      total_nights
      booking_amount {
        adult
        cwb
        cnb
        infant
      }
      ageBands {
        type
        ageFrom
        ageTo
      }
      destinations_covered
      overview_long
      active
      ims_pricing
      slug
      redirect_url
      isPublishRequest
      isPublished
      isRejected
      is_b2c
      is_b2b
      is_wl
      is_feature
      rejectedMessage
      seo {
        pagetitle
        metadescription
        metakeywords
        url
      }
    }
  }
`;
export const GET_PACKAGE_DETAILS = gql`
  query ($code: String!) {
    getPackageByCode(code: $code) {
      name
      code
      total_nights
      booking_amount {
        adult
        cwb
        cnb
        infant
      }
      inclusions
      images {
        name
        is_search
        path
        type
        tags
      }
      exclusions
      services_included {
        _id
        name
        service_id
        icon
      }
      ageBands {
        type
        ageFrom
        ageTo
      }
      themes {
        _id
        name
        description
        iconPath
      }
      itinerary {
        order
        heading
        details
      }
      pkg_origin {
        id
        destinationName
      }
      pkg_destinations {
        id
        destinationName
        countryName
        countryId
      }
      package_offers
      destinations_covered
      overview_long
      active
      ims_pricing
      ims_airline
      ims_markup_discount
      ims_setting
      slug
      redirect_url
      is_b2c
      is_b2b
      is_wl
      file
      pkg_type
      extraData
      hotel_id
      booking_window
      terms_conditions {
        text
        others
      }
      documents_required {
        text
        items
        others
      }
      additional_information
      visa_information {
        text
        others
      }
      seo {
        pagetitle
        metadescription
        metakeywords
        url
      }
      important_info
      options
      is_feature
      isPublishRequest
      isPublished
      isRejected
      flights
      sightseeing
      trains
      transfers
      bus
      cruise
      meals {
        breakfast
        breakfast_amount
        lunch
        lunch_amount
        dinner
        dinner_amount
      }
      visa
      taxes
    }
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation ($type: PkgDetails!, $data: UpdatePackage) {
    updatePackage(type: $type, data: $data) {
      code
      name
      active
      ims_pricing
      ims_airline
      ims_markup_discount
    }
  }
`;

export const GET_THEMES_ICONS = gql`
  query {
    getIcons {
      _id
      name
      description
      icon_type
      iconPath
    }
  }
`;

export const GET_PACKAGE_SERVICES = gql`
  query {
    getPackageServices {
      _id
      name
      service_id
      icon
    }
  }
`;
/* Sightseeing Modules */
export const GET_PACKAGE_SIGHTSEEING_LIST = gql`
  query ($city_id: ID, $service: String) {
    getAllPkgSightseeing(city_id: $city_id, service: $service) {
      _id
      name
      city
      duration
      timing
      description
      image_url
      pricing
      service
      seat_type
    }
  }
`;

export const CREATE_PACKAGE_SIGHTSEEING_DATA = gql`
  mutation ($data: NewSightseeingInput) {
    createAndUpdateSightseeing(data: $data) {
      _id
      name
    }
  }
`;

/* Hotel Modules */
export const GET_PACKAGE_HOTELS_LIST = gql`
  query ($city_id: ID, $type: ID) {
    getAllPkgHotels(city_id: $city_id, type: $type) {
      _id
      name
      city
      rating
      address
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PACKAGE_HOTEL_DATA = gql`
  mutation ($data: NewHotelInput) {
    createAndUpdateHotel(data: $data) {
      _id
      name
    }
  }
`;

export const GET_PACKAGE_HOTELS_TYPE_LIST = gql`
  query {
    getAllPkgHotelsType {
      _id
      name
    }
  }
`;

export const MAKE_PACKAGE_PUBLISH = gql`
  mutation ($input: publishRequest) {
    makePackagePublish(input: $input) {
      name
      code
      message
    }
  }
`;

export const GET_PACKAGE_MEDIA_LIST = gql`
  query ($data: MediaListing!) {
    getMedia(data: $data) {
      _id
      dest_id
      images {
        name
        type
        path
        tags
      }
    }
  }
`;

export const GET_AIRLINES_LIST = gql`
  query {
    getAirlines {
      name
      code
    }
  }
`;

export const FETCH_IMS_AIRLINES = gql`
  query {
    fetchImsAirlines
  }
`;

export const GET_AGENCY_TERMS_CONDITIONs = gql`
  query ($input: String!) {
    affiliateExists(input: $input) {
      terms_conditions
    }
  }
`;

export const GET_PKG_DESTINATIONS = gql`
  query {
    getPkgDestinations {
      id
      destinationName
      cities {
        id
        destinationName
      }
    }
  }
`;

export const UPDATE_AGENCY_TERMS_CONDITION = gql`
  mutation ($input: UpdateInfoAndContact!) {
    updateInfoAndContact(input: $input) {
      agencyid
      name
    }
  }
`;

export const UPDATE_PACKAGE_FEATURE = gql`
  mutation ($_id: ID!, $is_feature: Boolean!) {
    updateFeaturePackage(_id: $_id, is_feature: $is_feature) {
      code
      message
    }
  }
`;

export const FETCH_PACKAGE_DATES_PRICING = gql`
  query FetchPackageDates($code: String!) {
    fetchAvailableDates(code: $code)
  }
`;

export const UPDATE_PACKAGE_DATE_PRICING = gql`
  mutation UpdateDateAndPricing($payload: PackagesDatesPricing) {
    insertAndUpdateDates(payload: $payload) {
      code
      status
      message
    }
  }
`;

export const GET_PACKAGE_CITIES_DESTINATIONS = gql`
  query packageDestinations {
    pkg_destinations_cities
  }
`;

export const UPLOAD_PACKAGE_FILE = gql`
  mutation UploadPackageFile($payload: JSON) {
    uploadPackageFile(payload: $payload)
  }
`;

export const REMOVE_PACKAGE_FILE = gql`
  mutation RemovePackageFile($payload: JSON) {
    removePackageFile(payload: $payload)
  }
`;
