<template>
  <h1>Welcome {{ user.name }} To Your Dashboard</h1>
</template>

<script>
export default {
  name: "home",
  computed: {
    user() {
      return this.$store.getters.user;
    }
  }
};
</script>
