<template>
  <ul class="pkg-calendar-days">
    <li
      v-for="date in alldates"
      :class="date.className"
      @click.prevent="
        $emit('onSelectDate', date.day), (changedate = !changedate)
      "
      :key="$moment(date.day).format('DD/MM/YYYY')"
    >
      {{ $moment(date.day).format("DD") }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "dates",
  props: {
    date: {
      type: String
    },
    highlightDates: { type: Object },
    onSelectDate: { type: Function }
  },
  data() {
    return {
      alldates: [],
      changedate: false
    };
  },
  created() {
    this.alldates = this.dates(this.highlightDates);
  },
  watch: {
    date() {
      this.alldates = this.dates(this.highlightDates);
    },
    changedate() {
      this.alldates = this.dates(this.highlightDates);
    },
    highlightDates: {
      deep: true,
      handler() {
        this.alldates = this.dates(this.highlightDates);
      }
    }
  },
  methods: {
    dates() {
      const date = this.$moment(this.date);
      let weekOffset = 0;
      const startOfMonth = date.startOf("month");

      let diff = startOfMonth.weekday() - weekOffset;
      if (diff < 0) diff += 7;
      const prevMonthDays = [];
      for (let i = 0; i < diff; i++) {
        prevMonthDays.push({
          day: startOfMonth.clone().subtract(diff - i, "days"),
          className: "disabled"
        });
      }
      /* Current Month dates with Selection dates */
      const currentMonthDays = [];
      for (let i = 1; i < date.daysInMonth() + 1; i++) {
        if (
          /* Check Selected date is Same Month Of active */
          this.highlightDates &&
          this.highlightDates.hasOwnProperty([date.format("MM-YYYY")]) &&
          /* Check selecteDate is Array Type */
          Array.isArray(this.highlightDates[date.format("MM-YYYY")]) &&
          /* Find The Selected Is And Active */
          this.highlightDatesIndex(date, i) != -1
        ) {
          /* Get Index of match dates  */
          const matchIndex = this.highlightDatesIndex(date, i);
          /* Get Price And Active deactive Value of dates  */
          const matchValue = this.highlightDates[date.format("MM-YYYY")][
            matchIndex
          ];
          currentMonthDays.push({
            day: this.$moment([date.year(), date.month(), i]),
            className: this.checkDateClasses(
              this.$moment(`${date.format("YYYYMM")}${matchValue.value}`),
              matchValue
            ),
            price: matchValue.prices
          });
        } else {
          if (
            this.$moment([date.year(), date.month(), i + 1]).isBefore(
              this.$$moment
            )
          ) {
            currentMonthDays.push({
              day: this.$moment([date.year(), date.month(), i]),
              className: "disabled",
              price: {}
            });
          } else {
            currentMonthDays.push({
              day: this.$moment([date.year(), date.month(), i]),
              className: "",
              price: {}
            });
          }
        }
      }

      const daysAdded = prevMonthDays.length + currentMonthDays.length - 1;

      const nextMonthDays = [];
      let i = 1;
      while ((daysAdded + i) % 7 !== 0) {
        nextMonthDays.push({
          day: currentMonthDays[currentMonthDays.length - 1].day
            .clone()
            .add(i, "days"),
          className: "disabled"
        });
        i = i + 1;
      }

      return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
    },
    highlightDatesIndex(date, i) {
      return this.highlightDates[date.format("MM-YYYY")].findIndex(data => {
        const matchDate = this.$moment(`${date.format("YYYYMM")}${data.value}`);
        return matchDate.isSame(this.$moment([date.year(), date.month(), i]));
      });
    },
    checkDateClasses(value, item) {
      if (value.isBefore(this.$moment().subtract(1, "days"))) {
        return "disabled";
      } else if (
        item.prices &&
        item.prices.adult &&
        item.active &&
        item.prices.adult.surcharge !== ""
      ) {
        return "surchange-date";
      } else if (item.update) {
        return "update-date";
      } else {
        return item.active ? "active" : "deactive";
      }
    }
  }
};
</script>
