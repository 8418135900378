import {
  GET_DESTINATIONS,
  GET_DESTINATIONS_BY_NAME,
  GET_DESTINATIONS_BY_CITY_NAME
} from "../../queries/destination.queries";
import { defaultClient as apolloClient } from "../../main";
import { GET_PACKAGE_CITIES_DESTINATIONS } from "../../queries/packages.queries";
const state = {
  destinations: []
};
const mutations = {
  setDestinations: (state, payload) => {
    state.destinations = payload;
  }
};
const actions = {
  getDestinations: ({ commit }, payload) => {
    return new Promise(resolve => {
      commit("setLoading", true);
      //use apoloClient to fire getAffiliates query
      apolloClient
        .query({
          query: GET_DESTINATIONS,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data);
          // get data from action to state via mutation.
          // commit allows us to pass data from actions to state through the use of mutations.
          commit("setDestinations", data.getDestinations);
          commit("setLoading", false);
        })
        .catch(err => {
          commit("setLoading", false);
          //console.log(err);
        });
    });
  },
  getDestinationByName: (_, name) => {
    return new Promise(resolve => {
      apolloClient
        .query({
          query: GET_DESTINATIONS_BY_NAME,
          variables: { input: name }
        })
        .then(({ data }) => {
          resolve(data.filterDestinationByName);
        })
        .catch(err => {
          //console.log(err);
        });
    });
  },
  getCityListByName: (_, name) => {
    return new Promise(resolve => {
      apolloClient
        .query({
          query: GET_DESTINATIONS_BY_CITY_NAME,
          variables: { input: name }
        })
        .then(({ data }) => {
          resolve(data.getCityListByName);
        })
        .catch(err => {
          //console.log(err);
        });
    });
  },
  getPackageDestinationsCities() {
    return new Promise(resolve => {
      apolloClient
        .query({
          query: GET_PACKAGE_CITIES_DESTINATIONS
        })
        .then(({ data }) => {
          resolve(data.pkg_destinations_cities);
        })
        .catch(err => {
          //console.log(err);
        });
    });
  }
};
const getters = {
  destinations: state => state.destinations
};
export default {
  state,
  actions,
  getters,
  mutations
};
