<template>
  <div
    id="slideoverlay"
    class="slide-o"
    :class="{ 'slide-out scrollbarChrome': sideNav }"
  >
    <div @click="handleSideNavOpen" class="w-100 d-flex justify-content-end">
      <!-- <span class="icons open-close ic-align" :class="{ 'i-rot': sideNav }"></span> -->
      <span class="open-close" :class="{ 'i-rot': sideNav }">
        <svg width="16" height="16" viewBox="0 0 24 24" style="fill: #f2f2f2">
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
      </span>
    </div>
    <ul class="nav flex-column flex-nowrap w-180">
      <li
        v-for="(prntitem, index) in user.menuitems"
        :key="prntitem._id"
        data-type="parent-menu"
        class="nav-item"
        :class="{ active: activeIndex === index }"
      >
        <a
          @click="activeParent(index)"
          class="parent nav-link"
          id="1"
          :title="prntitem.name"
        >
          <component :is="prntitem.icon" />
          <!-- <i class="icons" :class="[prntitem.icon]"></i>&nbsp; -->
          <span class="d-inline p-2">{{ prntitem.name }}</span>
        </a>
        <transition
          @enter="expandEnter"
          @afterEnter="expandAfterEnter"
          @beforeLeave="expandBeforeLeave"
          name="expand"
          mode="out-in"
        >
          <ul
            v-if="activeIndex === index"
            class="flex-column nav ml-5 sub-menu"
          >
            <keep-alive v-for="child in prntitem.child" :key="child._id">
              <!-- child.pageUrl.indexOf('/create') == -1  -->
              <router-link
                v-if="child.isSideMenu"
                @click.native="handleSideNavOpen"
                tag="li"
                :to="child.pageUrl"
              >
                <a>{{ child.name }}</a>
              </router-link>
            </keep-alive>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
  <!-- <div :class="{ 'tp-bg': sideNav }" @click="handleSideNavOpen"></div> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Security from "../UI/icons/security.vue";
import Packages from "../UI/icons/packages.vue";
import Masters from "../UI/icons/masters.vue";
import Reports from "../UI/icons/reports.vue";
export default {
  name: "sidenav",
  data() {
    return {
      activeIndex: undefined
    };
  },
  components: { Security, Packages, Masters, Reports },
  computed: {
    ...mapGetters(["user"]),
    ...mapState({
      sideNav: state => state.layout.isSideNavOpen
    })
  },
  methods: {
    ...mapActions(["handleSideNav"]),
    activeParent(index) {
      if (!this.sideNav) {
        this.handleSideNav();
      }
      if (this.activeIndex == index) {
        this.activeIndex = undefined;
      } else {
        this.activeIndex = index;
      }
    },
    handleSideNavOpen() {
      this.handleSideNav();
      this.activeIndex = undefined;
    },
    expandEnter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    expandAfterEnter(el) {
      el.style.height = "auto";
    },
    expandBeforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    renderChildMenus(data) {
      return data.filter(val => val.isSideMenu);
    }
  }
};
</script>

<style>
#slideoverlay .expand-enter-active,
#slideoverlay .expand-leave-active {
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
  overflow: hidden;
}
#slideoverlay .expand-enter,
#slideoverlay .expand-leave-to {
  height: 0 !important;
}

.nav-link svg {
  fill: #5a738e;
  margin-right: 5px;
}

a:hover svg {
  fill: var(--white);
}

.nav-item > .parent.nav-link {
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.router-link-active {
  border-left: 1px solid #fff !important;
}
.router-link-active a {
  color: white !important;
}

.nav-item.parent.nav-link > span {
  width: 100%;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .nav-item > .parent.nav-link {
    padding: 0.35rem 0.45rem;
  }
  .nav-link svg {
    margin: 0;
  }
}
</style>
