import { defaultClient as apolloClient } from "../../../main";
import {
  GET_PKG_DISCOUNT_BY_CODE,
  UPDATE_DISCOUNTS,
  GET_ALL_DISCOUNTS
} from "../../../queries/discount.queries";

const state = {
  discountPkg: {},
  pkgDiscountLoading: false,
  newDateRange: [],
  updatingDiscount: false,
  deletingDiscount: false,
  isSearchingDiscount: false
};
const mutations = {
  setPkgDiscount(state, payload) {
    state.discountPkg = payload;
  },
  setPkgDicountLoading(state, value) {
    state.pkgDiscountLoading = value;
  },
  setupdateDiscount(state, value) {
    state.updatingDiscount = value;
  },
  setdeleteDiscount(state, value) {
    state.deletingDiscount = value;
  },
  setNewDateRange(state, payload) {
    state.newDateRange = payload;
  },
  setSearhingDiscounts(state, value) {
    state.isSearchingDiscount = value;
  }
};
const actions = {
  getPkgByCode: ({ commit }, code) => {
    commit("setPkgDicountLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_PKG_DISCOUNT_BY_CODE,
          variables: { code }
        })
        .then(({ data }) => {
          resolve(data.getPkgByCode);
          commit("setPkgDicountLoading", false);
          commit("setPkgDiscount", data.getPkgByCode);
        })
        .catch(err => {
          commit("setPkgDicountLoading", true);
          reject(err);
          //console.log(err);
        });
    });
  },
  getDiscountsList: ({ commit }, payload) => {
    commit("setSearhingDiscounts", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_ALL_DISCOUNTS,
          variables: { input: payload }
        })
        .then(({ data }) => {
          const finalData = data.getDiscountsList.filter(val => {
            delete val.__typename;
            delete val.discount.__typename;
            val.isSelected = false;
            return val;
          });
          resolve(finalData);
          commit("setSearhingDiscounts", false);
          commit("setNewDateRange", finalData);
        })
        .catch(err => {
          commit("setSearhingDiscounts", true);
          reject(err);
          //console.log(err);
        });
    });
  },
  updateAndDeleteDiscount: ({ commit }, payload) => {
    commit(`set${payload.type}Discount`, true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: UPDATE_DISCOUNTS,
          variables: { ...payload }
        })
        .then(({ data }) => {
          const finalData = data.updateAndDeleteDiscount.filter(val => {
            delete val.__typename;
            delete val.discount.__typename;
            val.isSelected = false;
            return val;
          });
          resolve(finalData);
          commit(`set${payload.type}Discount`, false);
          commit("setNewDateRange", finalData);
        })
        .catch(err => {
          commit(`set${payload.type}Discount`, true);
          reject(err);
          //console.log(err);
        });
    });
  }
};
const getters = {
  discountPkg: state => state.discountPkg,
  newDateRange: state => state.newDateRange,
  pkgDiscountLoading: state => state.pkgDiscountLoading,
  updatingDiscount: state => state.updatingDiscount,
  deletingDiscount: state => state.deletingDiscount,
  isSearchingDiscount: state => state.isSearchingDiscount
};

export default {
  state,
  mutations,
  actions,
  getters
};
