<template>
  <span class="chevron">
    <svg
      version="1.1"
      id="Svg1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="12px"
      height="7px"
      viewBox="0 0 12 7"
      style="enable-background: new 0 0 12 7;"
      xml:space="preserve"
    >
      <g>
        <path
          style="fill: #3C3C3C;"
          d="M0.285,6.201l0.577,0.535C1.063,6.912,1.296,7,1.563,7c0.273,0,0.504-0.088,0.694-0.264L6,3.272
		l3.743,3.464C9.934,6.912,10.164,7,10.437,7c0.267,0,0.501-0.088,0.7-0.264l0.57-0.535C11.902,6.021,12,5.805,12,5.553
		c0-0.256-0.098-0.471-0.293-0.642l-5.014-4.64C6.508,0.09,6.277,0,6,0C5.728,0,5.494,0.09,5.299,0.271l-5.014,4.64
		C0.096,5.088,0,5.301,0,5.554C0,5.801,0.096,6.017,0.285,6.201L0.285,6.201z M0.285,6.201"
        ></path>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "accordianArrow"
};
</script>

<style></style>
