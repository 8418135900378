import { gql } from "apollo-boost";

export const GET_DESTINATIONS = gql`
  query($input: setDestination!) {
    getDestinations(input: $input) {
      id
      destinationName
      destinationCode
      destinationId
      destinationType
      parentId
      active
      countryName
      name
    }
  }
`;

export const GET_DESTINATIONS_BY_NAME = gql`
  query($input: String!) {
    filterDestinationByName(input: $input) {
      id
      destinationName
      destinationCode
      destinationId
      destinationType
      parentId
      countryName
      name
    }
  }
`;

export const GET_DESTINATIONS_BY_CITY_NAME = gql`
  query($input: String!) {
    getCityListByName(input: $input) {
      id
      destinationName
      countryName
      countryId
      stateName
      name
    }
  }
`;
