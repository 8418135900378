import { gql } from "apollo-boost";

export const GET_AFFILIATE_USERS = gql`
  query {
    getAffiliateUsers {
      id
      name
      username
      email
      isActive
    }
  }
`;

export const GET_AFFILIATE_USER_BY_ID = gql`
  query ($input: String!) {
    getAffiliateUserById(input: $input) {
      id
      name
      username
      email
      isActive
    }
  }
`;

export const CREATE_AFFILIATE_USER = gql`
  mutation ($input: NewLogindetails!) {
    createAffiliateuser(input: $input) {
      id
    }
  }
`;

export const UPDATE_AFFILIATE_USER = gql`
  query ($input: UpdateAffilaiteByParent!) {
    userAffiliateEdit(input: $input) {
      id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($input: NewAffiliatePassword!) {
    changeAffiliatePassword(input: $input) {
      id
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation ($input: UpdateUserProfile!) {
    updateUserProfile(input: $input) {
      agencyid
      name
    }
  }
`;
export const GET_USER_BY_ID = gql`
  query ($input: String!) {
    affiliateExists(input: $input) {
      id
      agencyid
      name
      shortname
      websiteurl
      logo
      currency
      bookingemail
      cancellationemail
      contactdetails {
        address
        contactname
        city
        state
        country
        pincode
        emailid
        phonenumber
      }
      logindetails {
        name
        username
        password
        isActive
      }
      apicredentials {
        serviceType
        key
        ipaddress
        isActive
      }
    }
  }
`;
