import { gql } from "apollo-boost";

export const GET_ASSIGN_MENUS = gql`
  query($compType: String!) {
    getMenuForUsers(compType: $compType) {
      _id
      parentname
      submenu {
        active {
          _id
          name
          parent
        }
        notactive {
          _id
          name
          parent
        }
      }
    }
  }
`;

export const GET_USER_MENUS = gql`
  query($user_id: String!) {
    getAffiliateUserMenus(user_id: $user_id) {
      _id
      name
      menuitems
    }
  }
`;

export const ASSIGN_MENUS_TO_USER = gql`
  mutation($data: [String]!, $_id: String!, $compType: String) {
    assignMenusToUser(data: $data, _id: $_id, compType: $compType) {
      username
    }
  }
`;
