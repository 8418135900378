<template>
  <div>
    <div class="head_block-height">
      <div class="container-fluid px-1">
        <div class="row gutter14 align-items-center justify-content-between">
          <div class="col-7 col-md-5">
            <h5 class="m-0" v-html="headTitle"></h5>
          </div>
          <div class="col-5 col-md-7">
            <slot name="createbtn"></slot>
          </div>
        </div>
      </div>
      <hr class="hr-item" />
    </div>

    <div class="container-content scrollbarChrome">
      <div class="sticky-top px-1" style="left: 0">
        <slot name="sticky-content"></slot>
      </div>

      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    headTitle: { type: String, default: "Default Title" }
  }
};
</script>

<style scoped>
.hr-item {
  margin-bottom: 0.6rem;
}
.container-content {
  position: relative;
  height: calc(100vh - 90px);
  max-width: 97.3vw;
  overflow: auto;
  padding: 0px 8px;
}
</style>
