import { defaultClient as apolloClient } from "../../main";
import {
  CREATE_AND_UPDATE_INFO,
  GET_DEST_INFO,
  GET_DEST_INFO_BY_ID
} from "../../queries/information.queries";

const state = {
  information: [],
  // userEdit: {},
  // userExists: false,
  error: null
};
const mutations = {
  clearError: () => (state.error = null),
  setError: (state, payload) => {
    state.error = payload;
  },
  setInfo: (state, payload) => {
    state.information = payload;
  }
};
const actions = {
  createAndUpdateInfo: ({ commit }, payload) => {
    commit("clearError");
    return new Promise(resolve => {
      apolloClient
        .mutate({
          mutation: CREATE_AND_UPDATE_INFO,
          variables: { data: { ...payload } }
        })
        .then(data => {
          if (data.data) {
            resolve(data.data.createDestnRequirements);
          }
          //router.push("/masters/info/listing");
        })
        .catch(err => {
          //console.log(err);
        });
    });
  },
  getDestInfo: ({ commit }) => {
    //use apoloClient to fire getUsers query
    apolloClient
      .query({
        query: GET_DEST_INFO
      })
      .then(({ data }) => {
        // get data from action to state via mutation.
        // commit allows us to pass data from actions to state through the use of mutations.
        commit("setInfo", data.getDestInfo);
      })
      .catch(err => {
        return err;
      });
  },
  getDestInfoById: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .query({
          query: GET_DEST_INFO_BY_ID,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.getDestInfoById);
        })
        .catch(err => {
          reject(err);
          commit("setError", err);
        });
    });
  }
};

const getters = {
  information: state => state.information,
  infoerror: state => state.error
  //   userExists: state => state.userExists,
  //   userEdit: state => state.userEdit
};
export default {
  actions,
  state,
  getters,
  mutations
};
