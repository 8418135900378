import store from "./store";

export default (to, from, next) => {
  if (!store.getters.user) {
    store.watch(
      (state, getters) => getters.loading,
      async value => {
        if (!value && store.getters.user) {
          const foundUser = await checkValidRoutes(to, store.getters.user);
          if (foundUser) {
            next();
          } else {
            next({ path: "/" });
          }
        }
        if (!value && !store.getters.user) next({ path: "/signin" });
      }
    );
  } else {
    next();
  }
};

export function checkValidRoutes(to, user) {
  return new Promise(resolve => {
    let foundRoute = false;
    let ownRoutes = [
      "/",
      "/companydetails",
      "/viewprofile",
      "/change-password"
    ];
    for (const parItem of user.menuitems) {
      for (const child of parItem.child) {
        if (ownRoutes.includes(to.path) || child.pageUrl == to.path) {
          foundRoute = true;
          break;
        }
      }
    }
    resolve(foundRoute);
  });
}
