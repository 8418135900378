<template>
  <div class="container">
    <div class="main-content notfround">
      <h1>404</h1>
      <br />
      <h4 class="ml-3">Page Not Found</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "notfound"
};
</script>
