<template>
  <div class="body-set">
    <template v-if="!user">
      <router-view></router-view>
    </template>
    <template v-else>
      <!--header section-->
      <the-header />
      <!--slide out menu latest-->
      <div class="d-flex flex-wrap" style="height: 100vh">
        <the-side-nav />
        <!-- page content -->
        <div class="main-content" :class="{ active: sideNav }">
          <router-view></router-view>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TheHeader from "./components/Navigation/Header";
import TheSideNav from "./components/Navigation/SideNav";

export default {
  name: "app",
  computed: {
    ...mapState({
      sideNav: state => state.layout.isSideNavOpen
    }),
    user() {
      return this.$store.getters.user;
    }
  },
  components: {
    TheHeader,
    TheSideNav
  }
};
</script>

<style>
.body-set {
  overflow: hidden;
  width: 100%;
  position: relative;
}
hr {
  margin-top: 0.4rem;
}
.title-head {
  font-size: 16px;
}
.add-user:hover,
.add-user:active,
.add-user:visited,
.add-user:focus {
  background-color: transparent !important;
}
.add-user:active {
  color: inherit !important;
}
.form-group.invalid input {
  border: 1px solid red;
}
.form-group.invalid label {
  color: red;
}
.form-group.invalid select {
  border: 1px solid red;
}
.form-group.invalid .form-check-label {
  color: red;
}
.form-group.invalid span.text-muted {
  color: red !important;
  font-size: 10px;
  position: relative;
  top: -3px;
}

.form-group label.required::after {
  content: "*";
  display: inline-block;
  color: red;
  margin-left: 3px;
}

.t-row {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  border-bottom: unset !important;
}

.overflow {
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing .row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-info {
  color: #fff !important;
  background-color: #138496 !important;
  border-color: #117a8b !important;
}

.list-btn {
  border-radius: 0 !important;
  padding: 0px 11px !important;
  background: #17a2b8;
  color: #fff !important;
  margin: 0 5px !important;
  font-size: 10px !important;
}

.overflow-hide {
  overflow: hidden;
}

.clearfix {
  clear: both;
}
</style>
