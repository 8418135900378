<template>
  <div>
    <div
      class="popup_overlay"
      :class="{ 'popup_overlay-visible': openModel }"
      @click.prevent="$emit('close-model')"
    ></div>
    <div
      :style="bodystyle"
      class="popup_body scrollbarChrome"
      role="dialog"
      :class="{ popup_open: openModel }"
    >
      <div class="title-container">
        <div>
          <slot name="title"></slot>
        </div>
        <button
          @click.prevent="$emit('close-model')"
          :class="{ 'd-none': !closeBtn }"
          type="button"
          class="btn popup_close-btn ripple"
        >
          &times;
        </button>
      </div>
      <div>
        <div v-if="title && title != ''">
          <span class="pop-title">{{ title }}</span>
          <hr />
        </div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopUpModel",
  props: {
    closeBtn: { type: Boolean, default: true },
    openModel: { type: Boolean, default: false },
    bodystyle: { type: String },
    title: { type: String },
    closeModel: { type: Function }
  }
};
</script>

<style>
.popup_overlay,
.popup_body {
  position: fixed;
  opacity: 0;
  z-index: 1050;
  visibility: hidden;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.popup_overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
.popup_body {
  background: #fff;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 4px;
  max-width: 920px;
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
}
.popup_open,
.popup_overlay-visible {
  opacity: 1;
  visibility: visible;
}
.popup_open {
  top: 50%;
}

.popup_close-btn {
  top: 0;
  right: 0;
  color: #8f8f8f;
  background: transparent;
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 4;
  padding: 0;
}
.pop-title {
  font-size: 20px;
  color: var(--gray);
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
