<template>
  <div class="pkg-calendar">
    <div class="pkg-calendar-heading">
      {{ date.format("MMMM YYYY") }}
      <div
        v-if="$moment().isBefore(date.format('YYYYMMDD'))"
        class="pkg-calendar-heading-prev"
        @click="prevButtonClick"
      >
        ‹
      </div>
      <div
        v-if="date.isBefore($moment().add(11, 'month'))"
        class="pkg-calendar-heading-next"
        @click="nextButtonClick"
      >
        ›
      </div>
    </div>
    <ul class="pkg-calendar-week">
      <li>Sun</li>
      <li>Mon</li>
      <li>Tue</li>
      <li>Wed</li>
      <li>Thu</li>
      <li>Fri</li>
      <li>Sat</li>
    </ul>
    <dates
      @onSelectDate="onSelectDate"
      :highlight-dates="highlightDates"
      :date="alldates.format('YYYYMMDD')"
    />
  </div>
</template>

<script>
import dates from "./components/dates";
export default {
  name: "Calender",
  components: {
    dates
  },
  props: {
    highlightDates: { type: Object },
    onSelectDate: { type: Function },
    onClickNext: { type: Function },
    onClickPrev: { type: Function },
    currentMonth: {
      type: Object,
      default: () => {
        if (this && this.$moment) {
          return this.$moment();
        }
      }
    }
  },
  data() {
    return {
      date: this.currentMonth
    };
  },
  computed: {
    alldates() {
      const newdate = this.date.clone();
      return newdate;
    }
  },
  methods: {
    nextButtonClick() {
      this.date = this.date.clone().add(1, "months");
      this.$emit("onClickNext", this.date);
    },
    prevButtonClick() {
      this.date = this.date.clone().subtract(1, "months");
      this.$emit("onClickPrev", this.date);
    }
  }
};
</script>

<style scoped>
* {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.pkg-calendar {
  background: #f2f2f2;
  padding: 0 10px 5px;
}
.pkg-calendar-heading {
  position: relative;
  color: #3c3c3c;
  font-weight: bold;
  font-size: 14px;
  padding: 15px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 15px;
  text-align: center;
  border-bottom: 1px solid #17a2b8;
}
.pkg-calendar-week,
.pkg-calendar >>> .pkg-calendar-days {
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
}
.pkg-calendar-week > li,
.pkg-calendar >>> .pkg-calendar-days > li {
  float: left;
  margin-right: 1%;
  margin-bottom: 6px;
  width: 13.4285%;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
}
.pkg-calendar-week > li:last-child,
.pkg-calendar >>> .pkg-calendar-days > li:nth-of-type(7n) {
  margin-right: 0;
}
.pkg-calendar-week > li {
  text-transform: uppercase;
  font-weight: bold;
  color: #3c3c3c;
}
.pkg-calendar >>> .pkg-calendar-days > li {
  color: #3c3c3c;
  cursor: pointer;
}
.pkg-calendar >>> .pkg-calendar-days > li.disabled {
  background: #e5e5e5;
  pointer-events: none;
  color: #8f8f8f;
}
.pkg-calendar >>> .pkg-calendar-days > li.active {
  background: #17a2b8;
  color: #fff;
}
.pkg-calendar >>> .pkg-calendar-days > li.deactive {
  background: #929292;
  color: #fff;
}
.pkg-calendar >>> .pkg-calendar-days > li.surchange-date {
  background: #5457ad;
  color: #fff;
}
.pkg-calendar >>> .pkg-calendar-days > li.update-date {
  background: #46a96d;
  color: #fff;
}
.pkg-calendar-heading-prev,
.pkg-calendar-heading-next {
  content: "";
  position: absolute;
  top: 5px;
  width: 40px;
  height: 40px;
  line-height: 30px;
  background: #e5e5e5;
  text-align: center;
  color: #8f8f8f;
  font-size: 40px;
  cursor: pointer;
}
.pkg-calendar-heading-prev {
  left: 0;
}
.pkg-calendar-heading-next {
  right: 0;
}
.pkg-calendar >>> .avail-dates {
  margin: 0;
  padding: 0 0 0 20px;
  overflow: hidden;
}
.pkg-calendar >>> .avail-dates > li {
  float: left;
  width: 20%;
}
</style>
