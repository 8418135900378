import { gql } from "apollo-boost";

/* User Sign In With Affialite Schema */
export const SIGNIN_USER = gql`
  mutation($agencyid: String!, $username: String!, $password: String!) {
    signinAffiliateUser(
      agencyid: $agencyid
      username: $username
      password: $password
    ) {
      token
    }
  }
`;

/* Get Current User Details From Session Affiliate Schema */
export const GET_CURRENT_USER = gql`
  query {
    getCurrentAffiliateUser {
      id
      agencyname
      agencyid
      name
      subagencyid
      menuitems
      username
      email
      phonenumber
    }
  }
`;
