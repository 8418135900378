import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import layout from "./modules/layout";
import user from "./modules/user";
import menus from "./modules/menus";
import destination from "./modules/destination";
import packages from "./modules/packages";
import currency from "./modules/currency";
//import media from "./modules/media";
import information from "./modules/information";
import packageAdvance from "./modules/packages/pkg-advance";
import discount from "./modules/packages/discount";
import available_dates from "./modules/packages/available_dates";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    layout,
    menus,
    user,
    destination,
    packages,
    discount,
    //media,
    information,
    currency,
    packageAdvance,
    available_dates
  }
});
