import { defaultClient as apolloClient } from "../../main";
import {
  GET_ASSIGN_MENUS,
  GET_USER_MENUS,
  ASSIGN_MENUS_TO_USER
} from "../../queries/menus.queries";
import _ from "lodash";
const state = {
  menusError: null,
  assignMenus: [],
  selectedMenus: {}
};

const mutations = {
  setAssignMenus: (state, payload) => {
    state.assignMenus = payload;
  },
  setSelectedMenuItem: (state, { parent, childId, itemByUser }) => {
    if (state.selectedMenus.hasOwnProperty(parent)) {
      if (
        Array.isArray(state.selectedMenus[parent]) &&
        !state.selectedMenus[parent].includes(childId)
      ) {
        state.selectedMenus[parent].push(childId);
      }
    } else if (itemByUser) {
      state.selectedMenus = itemByUser;
    } else {
      state.selectedMenus[parent] = [parent, childId];
    }
  },
  removeSelectedMenuItem: (state, { parent, index }) => {
    if (state.selectedMenus[parent].length > 2) {
      state.selectedMenus[parent].splice(index + 1, 1);
    } else {
      delete state.selectedMenus[parent];
    }
  },
  selectAllMenu: (state, { parent, items }) => {
    if (state.selectedMenus.hasOwnProperty(parent)) {
      for (let item of _.map(items, "_id")) {
        state.selectedMenus[parent].push(item);
      }
    } else {
      const filterData = _.map(items, "_id");
      state.selectedMenus[parent] = [parent, ...filterData];
    }
  },
  deSelectAllMenu: (state, { parent }) => {
    delete state.selectedMenus[parent];
  }
};

const actions = {
  getMenuForUsers: ({ commit }, { compType }) => {
    commit("clearError");
    commit("setLoading", true);
    return new Promise(resolve => {
      //use apoloClient to fire getAffiliates query
      apolloClient
        .query({
          query: GET_ASSIGN_MENUS,
          variables: { compType }
        })
        .then(({ data }) => {
          resolve(data.getMenuForUsers);
          commit("setAssignMenus", data.getMenuForUsers);
          commit("clearError");
          commit("setLoading", false);
        })
        .catch(err => {
          commit("clearError");
          commit("setLoading", true);
          //console.log(err);
        });
    });
  },
  getUserMenus: ({ commit }, user_id) => {
    return new Promise(async (resolve, reject) => {
      commit("clearError");
      await apolloClient
        .query({
          query: GET_USER_MENUS,
          variables: { user_id }
        })
        .then(({ data }) => {
          resolve(data.getAffiliateUserMenus);
        })
        .catch(err => {
          reject(err);
          commit("setError", err);
        });
    });
  },
  setMenusItemToUser: ({ commit }, payload) => {
    commit("clearError");
    return new Promise(resolve => {
      apolloClient
        .mutate({
          mutation: ASSIGN_MENUS_TO_USER,
          variables: { ...payload }
        })
        .then(({ data }) => {
          resolve(data.assignMenusToUser);
          commit("setLoading", false);
        })
        .catch(err => {
          commit("setLoading", false);
          commit("setError", err);
        });
    });
  }
};

const getters = {
  menus: state => state.menus,
  parentMenu: state => state.parentmenu,
  menusError: state => state.menusError,
  assignMenus: state => state.assignMenus,
  selectedMenus: state => state.selectedMenus
};

export default {
  state,
  actions,
  getters,
  mutations
};
