import Moment from "moment";
import { extendMoment } from "moment-range";

const Range = {
  install(Vue, options) {
    Vue.prototype.$momentrange = function() {
      const momentrange = extendMoment(Moment);
      return momentrange;
    };
  }
};

export default Range;
