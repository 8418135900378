import { defaultClient as apolloClient } from "../../main";
import { GET_CURRENCY } from "../../queries/currency.queries";

const state = {
  currency: [],
  currencyError: null
};

const mutations = {
  setCurrency: (state, payload) => {
    state.currency = payload;
  }
};

const actions = {
  getCurrency: ({ commit }) => {
    return new Promise(resolve => {
      //use apoloClient to fire getAffiliates query
      apolloClient
        .query({
          query: GET_CURRENCY
        })
        .then(({ data }) => {
          resolve(data);
          // get data from action to state via mutation.
          // commit allows us to pass data from actions to state through the use of mutations.
          commit("setCurrency", data.getCurrencies);
        })
        .catch(err => {
          //console.log(err);
        });
    });
  }
};
const getters = {
  currency: state => state.currency,
  currencyError: state => state.currencyError
};

export default {
  state,
  actions,
  getters,
  mutations
};
