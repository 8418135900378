const state = {
  isSideNavOpen: false
};
const mutations = {
  setSideNav: state => {
    state.isSideNavOpen = !state.isSideNavOpen;
  }
};
const actions = {
  handleSideNav: ({ commit }) => {
    commit("setSideNav");
  }
};
const getters = {
  sideNavOpen: state => state.isSideNavOpen
};

export default {
  state,
  mutations,
  actions,
  getters
};
